<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <h5 class="mb-0">
        Company Info
      </h5>
    </b-col>
    <b-col md="6">
      <b-form-group
        label="Registered country or region"
        label-for="countryCode"
      >
        <validation-provider
          ref="countryCode"
          #default="{ errors }"
          name="Registered country or region"
          rules="required"
        >
          <v-select
            id="countryCode"
            v-model="country_code"
            :clearable="false"
            :value="country_code"
            :options="countryOption"
            placeholder="Select One"
            :reduce="val => val.value"
            :state="errors.length > 0 ? false:null"
            @close="() => $refs.countryCode.validate()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6" />
    <b-col md="6">
      <b-form-group
        label="Company registration number"
        label-for="registrationNumber"
      >
        <validation-provider
          #default="{ errors }"
          name="Company registration number"
          rules="required|validRegistrationNumber"
        >
          <b-form-input
            id="registrationNumber"
            v-model="company_number"
            type="text"
            :state="errors.length > 0 ? false:null"
            placeholder="Registration number..."
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6" />
    <b-col md="6">
      <b-form-group
        label="Industry"
        label-for="industryCode"
      >
        <validation-provider
          ref="industryCode"
          #default="{ errors }"
          name="Industry"
          rules="required"
        >
          <v-select
            id="industryCode"
            v-model="industry_code"
            :clearable="false"
            :value="industry_code"
            :options="countryOption"
            placeholder="Select One"
            :reduce="val => val.value"
            :state="errors.length > 0 ? false:null"
            @close="() => $refs.countryCode.validate()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6" />
    <b-col md="6">
      <b-form-group
        label="Website"
        label-for="website"
      >
        <validation-provider
          #default="{ errors }"
          name="Website"
          rules="required|url"
        >
          <b-form-input
            id="website"
            v-model="website"
            type="text"
            :state="errors.length > 0 ? false:null"
            placeholder="https://www.website.com"
            :formatter="value => sliceStr(value, 200)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <div class="document-tips">
        <h3>To set up an account, please provide the following documents:</h3>
        <ul>
          <li>Company registration certificate</li>
          <li>Company policy </li>
          <li>Personal address information and identity documents of each company executive.</li>
          <li>Personal ID and address of the final beneficiary holding 25% or more of the company's shares (if applicable).</li>
        </ul>
      </div>
    </b-col>
    <b-col md="12">
      <b-form-group
        label-for="selected"
      >
        <validation-provider
          #default="{ errors }"
          name="selected"
          rules="selectedTrue"
        >
          <b-form-checkbox
            id="selected"
            v-model="selected"
          >
            I confirm that I will be the administrator of this business account and have been authorized to fill out this form on behalf of this business.
          </b-form-checkbox>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  defineComponent, ref, getCurrentInstance, onBeforeMount, computed, onMounted,
} from '@vue/composition-api'
import { ValidationProvider, extend } from 'vee-validate'
import vSelect from 'vue-select'
import store from '@/store'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import storage from '@/libs/storage'
import { sliceStr } from '@/libs/utils'

extend('selectedTrue', {
  validate(value) {
    return value
  },
  message: 'Please confirm',
})
extend('validRegistrationNumber', {
  validate(value) {
    const reg = /(?=.*[^a-zA-Z0-9])/
    return !reg.test(value)
  },
  message: 'The {_field_} is not valid',
})

export default defineComponent({
  name: 'BasicInfo',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    ValidationProvider,
    vSelect,
  },
  setup() {
    const { proxy } = getCurrentInstance()
    onBeforeMount(() => {
      // 获取公共数据
      if (store.state.wallet.countryList.length === 0) { store.dispatch('wallet/getCommon') }
    })
    // option
    const countryOption = computed(() => store.state.wallet.countryList)
    const country_code = ref('')
    const company_number = ref('')
    const industry_code = ref('')
    const website = ref('')
    const selected = ref(false)
    // country_code.value = countryOption.value[0].value
    // industry_code.value = countryOption.value[0].value
    proxy.$Bus.on('updateBasic', cb => {
      const params = {
        basic_info: {
          country_code: country_code.value,
          company_number: company_number.value,
          industry_code: industry_code.value,
          website: website.value,
        },
        reg_agreement_status: selected.value,
      }
      cb(params)
    })
    onMounted(() => {
      const info = storage.getItem('kycBasicInfo')
      if (info) {
        const { reg_agreement_status } = info
        const {
          company_number: companyNumber, country_code: countryCode, industry_code: industryCode, website: websiteValue,
        } = info.basic_info
        country_code.value = countryCode
        company_number.value = companyNumber
        industry_code.value = industryCode
        website.value = websiteValue
        selected.value = reg_agreement_status
      }
    })
    return {
      countryOption, country_code, company_number, industry_code, website, selected, sliceStr,
    }
  },

})
</script>

<style lang="scss">
  .document-tips {
    border: 1px solid #28c76f;
    border-radius: 9px;
    padding: 10px 15px 0;
    margin-bottom: 10px;
  }
</style>
