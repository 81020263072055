<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <h5 class="mb-0">
        Account Usage
      </h5>
    </b-col>
    <b-col md="8">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="From which country will you send money?"
      >
        <validation-provider
          ref="codeSendRef"
          #default="{ errors }"
          name="From which country will you send money?"
          rules="required"
        >
          <v-select
            v-model="countryCodeSend"
            :options="countryOption"
            placeholder="Select One"
            :reduce="val => val.value"
            :state="errors.length > 0 ? false:null"
            @option:selected="$refs['codeSendRef'].validate()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="4" />
    <b-col md="8">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="From which country will you recieve money?"
      >
        <validation-provider
          ref="codeReceiveRef"
          #default="{ errors }"
          name="From which country will you recieve money?"
          rules="required"
        >
          <v-select
            v-model="countryCodeReceive"
            :options="countryOption"
            placeholder="Select One"
            :reduce="val => val.value"
            :state="errors.length > 0 ? false:null"
            @option:selected="$refs['codeReceiveRef'].validate()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="4" />
    <b-col md="8">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="The total amount you expect to recieve is:"
      >
        <validation-provider
          #default="{ errors }"
          ref="validateTotalAmount"
          name="The total amount you expect to recieve is:"
          rules="required"
        >
          <b-form-checkbox
            v-for="option in totalAmountOptions"
            :key="option.value"
            v-model="totalAmountReceive"
            class="mt-1"
            :value="option.value"
            name="flavour-3a"
            @input="validateTotalAmountReceive"
          >
            {{ option.text }}
          </b-form-checkbox>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="4" />
    <b-col md="8">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="Your main purpose for using the Transfersmile Wallet is:"
      >
        <validation-provider
          #default="{ errors }"
          ref="validatePurpose"
          name="Your main purpose for using the Transfersmile Wallet is:"
          rules="required"
        >
          <b-form-checkbox
            v-for="option in purposeOptions"
            :key="option.value"
            v-model="purposeSelected"
            class="mt-1"
            :value="option.value"
            name="flavour-3a"
            @input="validatePurposeSelected"
          >
            {{ option.text }}
          </b-form-checkbox>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  defineComponent, ref, getCurrentInstance, onBeforeMount, computed, watch,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store'
import storage from '@/libs/storage'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,

    ValidationProvider,
    vSelect,
  },
  setup() {
    const { proxy } = getCurrentInstance()
    onBeforeMount(() => {
      // 获取公共数据
      // store.dispatch('wallet/getCommon')
    })
    // option
    const countryOption = computed(() => store.state.wallet.countryList)
    const countryCodeSend = ref('')
    const countryCodeReceive = ref('')
    const totalAmountReceive = ref('')
    const purposeSelected = ref('')

    const totalAmountOptions = ref([
      { text: '$0 to $5000 USD', value: '1' },
      { text: '$5000 to $10 000 USD', value: '2' },
      { text: '$10 000 to $25 000 USD', value: '3' },
      { text: '$25 000 to $150 000 USD', value: '4' },
      { text: 'More than $150 000 USD', value: '5' },
    ])
    const purposeOptions = ref([
      { text: 'Recieving funds from online customers', value: '1' },
      { text: 'Recieving funds from in store customers', value: '2' },
      { text: 'Dispersing Gaming rewards', value: '3' },
    ])

    proxy.$Bus.on('updateDetail', cb => {
      const params = {
        details: {
          country_code_send: countryCodeSend.value,
          country_code_receive: countryCodeReceive.value,
          total_amount_receive: totalAmountReceive.value,
          purpose: purposeSelected.value,
        },
      }
      // console.log('Detail - params', params)
      cb(params)
    })

    const validateTotalAmountReceive = () => {
      if (!totalAmountReceive.value) {
        totalAmountReceive.value = ''
        proxy.$refs.validateTotalAmount.validate()
        return false
      }
      return true
    }
    const validatePurposeSelected = () => {
      if (!purposeSelected.value) {
        purposeSelected.value = ''
        proxy.$refs.validatePurpose.validate()
        return false
      }
      return true
    }

    proxy.$nextTick(() => {
      const detail = storage.getItem('kycDetail')
      if (detail) {
        const {
          country_code_receive, country_code_send, purpose, total_amount_receive,
        } = detail.details
        countryCodeSend.value = country_code_send
        countryCodeReceive.value = country_code_receive
        totalAmountReceive.value = total_amount_receive
        purposeSelected.value = purpose
      }
    })
    return {
      countryOption,

      countryCodeSend,
      countryCodeReceive,
      totalAmountReceive,
      purposeSelected,

      totalAmountOptions,
      purposeOptions,
      validateTotalAmountReceive,
      validatePurposeSelected,
    }
  },
})
</script>
