var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.personArray),function(item,index){return _c('div',{key:index,staticClass:"documents-add"},[_c('div',{staticClass:"documents-add-header"},[_c('span',[_vm._v(_vm._s(_vm.smallTitle)+" "+_vm._s(index + 1)+":")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.remove(index, _vm.arrayName)}}},[_vm._v(" Remove ")])],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Country or Region of Document Issue","label-for":"issue1"}},[_c('validation-provider',{ref:("" + _vm.arrayName + index + "Region"),refInFor:true,attrs:{"name":(_vm.smallTitle + " " + (index + 1) + " Country or Region of Document Issue"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countryArray,"placeholder":"Select One","state":errors.length > 0 ? false:null,"reduce":function (val) { return val.value; }},on:{"option:selected":function($event){return _vm.selectChange(arguments[0], 'country_code', index, ("" + _vm.arrayName + index + "Region"))}},model:{value:(item[("" + _vm.arrayName + index + "_country_code")]),callback:function ($$v) {_vm.$set(item, ("" + _vm.arrayName + index + "_country_code"), $$v)},expression:"item[`${arrayName}${index}_country_code`]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-tabs',{attrs:{"lazy":""},on:{"activate-tab":function($event){return _vm.inputChange(arguments[0], 'tab_index', index)}},model:{value:(item['tab_index']),callback:function ($$v) {_vm.$set(item, 'tab_index', $$v)},expression:"item['tab_index']"}},[_c('b-tab',{key:("" + _vm.arrayName + index + "Front1"),attrs:{"title":"Identity Document"}},[_c('b-form-group',{attrs:{"label":"Front"}},[_c('validation-provider',{ref:("" + _vm.arrayName + index + "Front"),refInFor:true,attrs:{"name":(_vm.smallTitle + " " + (index + 1) + " Front"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"file-wrap"},[_c('b-form-file',{staticClass:"form-file",attrs:{"accept":".pdf,.jpg,.jpeg,.png,.bmp","value":item[("" + _vm.arrayName + index + "_id_front_url")],"placeholder":"Choose a file...","state":errors.length > 0 ? false:null,"browse-text":"upload"},on:{"change":function($event){return _vm.fileChange(arguments[0], 'id_front_url', index, ("" + _vm.arrayName + index + "Front"))}}}),_c('b-input-group',[_c('b-form-input',{attrs:{"value":item[("" + _vm.arrayName + index + "_id_front_url")] ? item[("" + _vm.arrayName + index + "_id_front_url")].name : '',"type":"text","placeholder":"Choose a file..."}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"}},[_vm._v(" Upload ")])],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Back"}},[_c('validation-provider',{ref:("" + _vm.arrayName + index + "Back"),refInFor:true,attrs:{"name":(_vm.smallTitle + " " + (index + 1) + " Back"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"file-wrap"},[_c('b-form-file',{staticClass:"form-file",attrs:{"accept":".pdf,.jpg,.jpeg,.png,.bmp","value":item[("" + _vm.arrayName + index + "_id_back_url")],"placeholder":"Choose a file...","state":errors.length > 0 ? false:null,"browse-text":"upload"},on:{"change":function($event){return _vm.fileChange(arguments[0], 'id_back_url', index, ("" + _vm.arrayName + index + "Back"))}}}),_c('b-input-group',[_c('b-form-input',{attrs:{"value":item[("" + _vm.arrayName + index + "_id_back_url")] ? item[("" + _vm.arrayName + index + "_id_back_url")].name : '',"type":"text","placeholder":"Choose a file..."}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"}},[_vm._v(" Upload ")])],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-tab',{key:("" + _vm.arrayName + index + "Passport1"),attrs:{"title":"Passport"}},[_c('b-form-group',{attrs:{"label":"Personal Info Page"}},[_c('validation-provider',{ref:("" + _vm.arrayName + index + "Passport"),refInFor:true,attrs:{"name":(_vm.smallTitle + " " + (index + 1) + " Personal Info Page"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"file-wrap"},[_c('b-form-file',{staticClass:"form-file",attrs:{"accept":".pdf,.jpg,.jpeg,.png,.bmp","value":item[("" + _vm.arrayName + index + "_passport_url")],"placeholder":"Choose a file...","state":errors.length > 0 ? false:null,"browse-text":"upload"},on:{"change":function($event){return _vm.fileChange(arguments[0], 'passport_url', index, ("" + _vm.arrayName + index + "Passport"))}}}),_c('b-input-group',[_c('b-form-input',{attrs:{"value":item[("" + _vm.arrayName + index + "_passport_url")] ? item[("" + _vm.arrayName + index + "_passport_url")].name : '',"type":"text","placeholder":"Choose a file..."}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"}},[_vm._v(" Upload ")])],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Place Of Birth"}},[_c('validation-provider',{ref:("" + _vm.arrayName + index + "birthPlace"),refInFor:true,attrs:{"name":(_vm.smallTitle + " " + (index + 1) + " Place of Birth"),"rules":"required|validPlaceOfBirth"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"value":item[("" + _vm.arrayName + index + "_birth_place")],"type":"text","state":errors.length > 0 ? false:null,"placeholder":"Enter place of birth"},on:{"change":function($event){return _vm.inputChange(arguments[0], 'birth_place', index, ("" + _vm.arrayName + index + "birthPlace"))}},model:{value:(item[("" + _vm.arrayName + index + "_birth_place")]),callback:function ($$v) {_vm.$set(item, ("" + _vm.arrayName + index + "_birth_place"), $$v)},expression:"item[`${arrayName}${index}_birth_place`]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }