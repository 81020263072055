var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Account Usage ")])]),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"4","label":"From which country will you send money?"}},[_c('validation-provider',{ref:"codeSendRef",attrs:{"name":"From which country will you send money?","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countryOption,"placeholder":"Select One","reduce":function (val) { return val.value; },"state":errors.length > 0 ? false:null},on:{"option:selected":function($event){return _vm.$refs['codeSendRef'].validate()}},model:{value:(_vm.countryCodeSend),callback:function ($$v) {_vm.countryCodeSend=$$v},expression:"countryCodeSend"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}}),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"4","label":"From which country will you recieve money?"}},[_c('validation-provider',{ref:"codeReceiveRef",attrs:{"name":"From which country will you recieve money?","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countryOption,"placeholder":"Select One","reduce":function (val) { return val.value; },"state":errors.length > 0 ? false:null},on:{"option:selected":function($event){return _vm.$refs['codeReceiveRef'].validate()}},model:{value:(_vm.countryCodeReceive),callback:function ($$v) {_vm.countryCodeReceive=$$v},expression:"countryCodeReceive"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}}),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"4","label":"The total amount you expect to recieve is:"}},[_c('validation-provider',{ref:"validateTotalAmount",attrs:{"name":"The total amount you expect to recieve is:","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._l((_vm.totalAmountOptions),function(option){return _c('b-form-checkbox',{key:option.value,staticClass:"mt-1",attrs:{"value":option.value,"name":"flavour-3a"},on:{"input":_vm.validateTotalAmountReceive},model:{value:(_vm.totalAmountReceive),callback:function ($$v) {_vm.totalAmountReceive=$$v},expression:"totalAmountReceive"}},[_vm._v(" "+_vm._s(option.text)+" ")])}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}}),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"4","label":"Your main purpose for using the Transfersmile Wallet is:"}},[_c('validation-provider',{ref:"validatePurpose",attrs:{"name":"Your main purpose for using the Transfersmile Wallet is:","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._l((_vm.purposeOptions),function(option){return _c('b-form-checkbox',{key:option.value,staticClass:"mt-1",attrs:{"value":option.value,"name":"flavour-3a"},on:{"input":_vm.validatePurposeSelected},model:{value:(_vm.purposeSelected),callback:function ($$v) {_vm.purposeSelected=$$v},expression:"purposeSelected"}},[_vm._v(" "+_vm._s(option.text)+" ")])}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }