<template>
  <div>
    <!-- {{ JSON.stringify(personArray) }} -->
    <div
      v-for="(item, index) in personArray"
      :key="index"
      class="documents-add"
    >
      <div class="documents-add-header">
        <span>{{ smallTitle }} {{ index + 1 }}:</span>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          size="sm"
          @click="remove(index, arrayName)"
        >
          Remove
        </b-button>
      </div>
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Country or Region of Document Issue"
            label-for="issue1"
          >
            <validation-provider
              :ref="`${arrayName}${index}Region`"
              #default="{ errors }"
              :name="`${smallTitle} ${index + 1} Country or Region of Document Issue`"
              rules="required"
            >
              <v-select
                v-model="item[`${arrayName}${index}_country_code`]"
                :options="countryArray"
                placeholder="Select One"
                :state="errors.length > 0 ? false:null"
                :reduce="val => val.value"
                @option:selected="selectChange(arguments[0], 'country_code', index, `${arrayName}${index}Region`)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <!-- v-model="item[`${arrayName}${index}_tab_index`]" -->
          <b-tabs
            v-model="item['tab_index']"
            lazy
            @activate-tab="inputChange(arguments[0], 'tab_index', index)"
          >
            <b-tab
              :key="`${arrayName}${index}Front1`"
              title="Identity Document"
            >
              <b-form-group
                label="Front"
              >
                <validation-provider
                  :ref="`${arrayName}${index}Front`"
                  #default="{ errors }"
                  :name="`${smallTitle} ${index + 1} Front`"
                  rules="required"
                >
                  <div class="file-wrap">
                    <!-- v-model="item[`${arrayName}${index}_id_front_url`]" -->
                    <b-form-file
                      class="form-file"
                      accept=".pdf,.jpg,.jpeg,.png,.bmp"
                      :value="item[`${arrayName}${index}_id_front_url`]"
                      placeholder="Choose a file..."
                      :state="errors.length > 0 ? false:null"
                      browse-text="upload"
                      @change="fileChange(arguments[0], 'id_front_url', index, `${arrayName}${index}Front`)"
                    />
                    <b-input-group>
                      <b-form-input
                        :value="item[`${arrayName}${index}_id_front_url`] ? item[`${arrayName}${index}_id_front_url`].name : ''"
                        type="text"
                        placeholder="Choose a file..."
                      />
                      <b-input-group-append>
                        <b-button variant="outline-primary">
                          Upload
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Back"
              >
                <validation-provider
                  :ref="`${arrayName}${index}Back`"
                  #default="{ errors }"
                  :name="`${smallTitle} ${index + 1} Back`"
                  rules="required"
                >
                  <div class="file-wrap">
                    <!-- v-model="item[`${arrayName}${index}_id_back_url`]" -->
                    <b-form-file
                      class="form-file"
                      accept=".pdf,.jpg,.jpeg,.png,.bmp"
                      :value="item[`${arrayName}${index}_id_back_url`]"
                      placeholder="Choose a file..."
                      :state="errors.length > 0 ? false:null"
                      browse-text="upload"
                      @change="fileChange(arguments[0], 'id_back_url', index, `${arrayName}${index}Back`)"
                    />
                    <b-input-group>
                      <b-form-input
                        :value="item[`${arrayName}${index}_id_back_url`] ? item[`${arrayName}${index}_id_back_url`].name : ''"
                        type="text"
                        placeholder="Choose a file..."
                      />
                      <b-input-group-append>
                        <b-button variant="outline-primary">
                          Upload
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-tab>
            <b-tab
              :key="`${arrayName}${index}Passport1`"
              title="Passport"
            >
              <b-form-group
                label="Personal Info Page"
              >
                <validation-provider
                  :ref="`${arrayName}${index}Passport`"
                  #default="{ errors }"
                  :name="`${smallTitle} ${index + 1} Personal Info Page`"
                  rules="required"
                >
                  <div class="file-wrap">
                    <!-- v-model="item[`${arrayName}${index}_passport_url`]" -->
                    <b-form-file
                      class="form-file"
                      accept=".pdf,.jpg,.jpeg,.png,.bmp"
                      :value="item[`${arrayName}${index}_passport_url`]"
                      placeholder="Choose a file..."
                      :state="errors.length > 0 ? false:null"
                      browse-text="upload"
                      @change="fileChange(arguments[0], 'passport_url', index, `${arrayName}${index}Passport`)"
                    />
                    <b-input-group>
                      <b-form-input
                        :value="item[`${arrayName}${index}_passport_url`] ? item[`${arrayName}${index}_passport_url`].name : ''"
                        type="text"
                        placeholder="Choose a file..."
                      />
                      <b-input-group-append>
                        <b-button variant="outline-primary">
                          Upload
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Place Of Birth"
              >
                <validation-provider
                  :ref="`${arrayName}${index}birthPlace`"
                  #default="{ errors }"
                  :name="`${smallTitle} ${index + 1} Place of Birth`"
                  rules="required|validPlaceOfBirth"
                >
                  <b-form-input
                    v-model="item[`${arrayName}${index}_birth_place`]"
                    :value="item[`${arrayName}${index}_birth_place`]"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter place of birth"
                    @change="inputChange(arguments[0], 'birth_place', index, `${arrayName}${index}birthPlace`)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  defineComponent, ref, getCurrentInstance, computed, watch, onUpdated,
} from '@vue/composition-api'
import { ValidationProvider, extend } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BTabs,
  BTab,
  BFormFile,
  BInputGroup, BInputGroupAppend, BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { showToast, subUrlName } from '@/libs/utils'
import useAccountVerification from '../useAccountVerification'

extend('validPlaceOfBirth', {
  validate(value) {
    const reg = /(?=.*[!@#$%^&*()])/
    return !reg.test(value)
  },
  message: 'The {_field_} is not valid',
})

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const { uploadFile } = useAccountVerification()
    const addItem = (item, itemIndex, comName, key) => {
      const itemIndex1 = itemIndex + 1
      // eslint-disable-next-line no-param-reassign
      if (item[key]) {
        // eslint-disable-next-line no-param-reassign
        item[`${comName}${itemIndex}_${key}`] = item[`${comName}${itemIndex1}_${key}`]
        // eslint-disable-next-line no-param-reassign
        delete item[`${comName}${itemIndex1}_${key}`]
      }
    }
    // 假设 index 为1，1之后的全部向前进1
    const remove = (index, name) => {
      if (proxy.personArray.length < 2) {
        showToast({ proxy, title: 'danger', message: `At least one ${proxy.arrayName}` })
        return
      }
      proxy.personArray.splice(index, 1)
      // 筛选 重新生成
      const newPersonArray = proxy.personArray.filter((item, itemIndex) => {
        if (itemIndex >= index) {
          const keys = ['country_code', 'id_front_url', 'id_back_url', 'passport_url', 'birth_place', 'tab_index']
          for (let i = 0; i <= keys.length; i += 1) {
            addItem(item, itemIndex, name, keys[i])
          }
          return item
        }
        return item
      })
      proxy.$emit('update:personArray', newPersonArray)
      // console.log('proxy.personArray => delete ->  ', newPersonArray)
      proxy.$emit('getData', proxy.personArray)
    }

    // 从文件中获取数据
    const fileChange = async (event, keyName, index, refName) => {
      const [singleFile] = event.target.files
      if (!singleFile) { return }
      // 限制上传文件的大小
      if (singleFile.size > 10 * 1024 * 1024) {
        showToast({ proxy, title: 'danger', message: 'The file cannot exceed 10mb' })
        return
      }
      // 上传文件 获取URL之后赋值
      proxy.personArray[index][keyName] = await uploadFile(singleFile)
      proxy.personArray[index][`${proxy.arrayName}${index}_${keyName}`] = new File([''], subUrlName(proxy.personArray[index][keyName]), { type: 'image/jpeg' })
      proxy.$emit('update:personArray', proxy.personArray)
      proxy.$emit('getData', proxy.personArray)
      if (!!refName && proxy.$refs[refName][0]) { proxy.$refs[refName][0].validate() }
    }

    const selectChange = (args, keyName, index, refName) => {
      proxy.personArray[index][keyName] = args.value
      proxy.$emit('update:personArray', proxy.personArray)
      // console.log('proxy.personArray => add ->  ', proxy.personArray)
      proxy.$emit('getData', proxy.personArray)
      if (refName) { proxy.$refs[refName][0].validate() }
    }
    const inputChange = (value, keyName, index, refName) => {
      proxy.personArray[index][keyName] = value
      proxy.$emit('update:personArray', proxy.personArray)
      // console.log('proxy.personArray => add ->  ', proxy.personArray)
      proxy.$emit('getData', proxy.personArray)
      if (refName) { proxy.$refs[refName][0].validate() }
    }
    return {
      remove, selectChange, inputChange, fileChange,
    }
  },
  props: {
    smallTitle: {
      type: String,
      default: '',
    },
    countryArray: {
      type: Array,
      default: () => [],
    },
    personArray: {
      type: Array,
      default: () => [],
    },
    arrayName: {
      type: '',
      default: '',
    },
  },
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    vSelect,
    BTabs,
    BTab,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
  },
  directives: {
    Ripple,
  },
})
</script>

<style lang="scss">
  .documents-add {
    .documents-add-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .file-wrap {
      position: relative;
      .form-file {
        position: absolute;
        width: 100%;
        z-index: 9;
        opacity: 0;
      }
    }
  }
</style>
