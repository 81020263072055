<template>
  <div>
    <b-navbar class="header-navbar navbar navbar-shadow align-items-center account-v">
      <ul class="nav">
        <li class="nav-item mr-auto">
          <b-link class="navbar-brand">
            <b-img
              :src="require('@/assets/images/logo/logo.png')"
              alt="logo"
            />
            <h2 class="brand-text">
              Merchant Center
            </h2>
          </b-link>
        </li>
      </ul>
      <b-navbar-nav class="nav align-items-center ml-auto">
        <user-dropdown />
      </b-navbar-nav>
    </b-navbar>

    <div class="wizard-main">
      <h4 class="wizard-main-title">
        Account Verification
      </h4>
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="formSubmitted"
        @on-change="formChanged"
      >

        <!-- Basic Info tab -->
        <tab-content
          title="Basic Info"
          :before-change="validationBasicInfo"
        >
          <validation-observer
            ref="basicInfoRules"
            tag="form"
          >
            <basic-info ref="basic" />
            <div style="height:20px" />
          </validation-observer>
        </tab-content>

        <!-- Documents tab -->
        <tab-content
          title="Documents"
          :before-change="validationDocuments"
        >
          <validation-observer
            ref="documentsRules"
            tag="form"
          >
            <documents />
            <div style="height:20px" />
          </validation-observer>
        </tab-content>

        <!-- Details tab  -->
        <tab-content
          title="Details"
          :before-change="validationDetails"
        >
          <validation-observer
            ref="detailsRules"
            tag="form"
          >
            <kyc-details />
            <div style="height:20px" />
          </validation-observer>
        </tab-content>

        <!-- Information Check tab -->
        <tab-content
          title="Information Check"
          :before-change="validationInformationCheck"
        >
          <validation-observer
            ref="checkRules"
            tag="form"
          >
            <information-check v-if="showValidationInformation" />
            <div style="height:20px" />
          </validation-observer>
        </tab-content>
      </form-wizard>
      <div style="height:50px" />
    </div>

  </div>

</template>

<script>
import {
  BNavbar,
  BNavbarNav,
  BLink,
  BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { FormWizard, TabContent } from 'vue-form-wizard'
import UserDropdown from '@/@core/layouts/components/app-navbar/components/UserDropdown.vue'
// 分步组件
import { ValidationObserver } from 'vee-validate'
import store from '@/store/index'
import { showToast } from '@/libs/utils'
import storage from '@/libs/storage'
import BasicInfo from './components/basicInfo.vue'
import Documents from './components/documents.vue'
import KycDetails from './components/details.vue'
import InformationCheck from './components/informationCheck.vue'

export default {
  name: 'AccountVerification',
  components: {
    BNavbar,
    BNavbarNav,
    BImg,
    BLink,
    // validations
    UserDropdown,

    FormWizard,
    TabContent,

    ValidationObserver,
    BasicInfo,
    Documents,
    KycDetails,
    InformationCheck,
  },
  data() {
    return {
      selectedContry: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
      showValidationInformation: false,
    }
  },
  methods: {
    formSubmitted() {
      const { basic_info: basicInfo, reg_agreement_status } = storage.getItem('kycBasicInfo') || {}
      const { documents } = storage.getItem('kycDocuments') || {}
      const { details } = storage.getItem('kycDetail') || {}
      const params = {
        basic_info: basicInfo, documents, details, reg_agreement_status,
      }
      // 保存kyc信息
      const that = this
      setTimeout(async () => {
        const res = await that.$api.saveKyc(params)
        const { code } = res.data
        if (code === 200) {
          showToast({ proxy: that, title: 'success', message: 'Success' })
          that.$router.push({ name: store.state.wallet.defaultRouteName || storage.getItem('defaultRouteName') || 'auth-login' })
          that.$swal({
            title: 'Registration Processing',
            text: 'Your account registration is currently under review. Please wait for approval.',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/account/refreshing.png'),
            imageWidth: 88,
            imageAlt: 'Custom image',
            showConfirmButton: false,
            allowOutsideClick: false,
            customClass: {
              image: 'kyc-status-refresh',
            },
            position: 'center',
          })
          // .then(result => {
          //   if (result.value) {
          //     // Remove userData from localStorage
          //     storage.clearItem('userData')
          //     // Redirect to login page
          //     that.$router.push({ name: 'auth-login' })
          //   }
          // })
        } else {
          showToast({ proxy: that, title: 'danger', message: 'Error' })
        }
      }, 0)
    },
    formChanged(prevIndex, nextIndex) {
      // console.log(prevIndex, nextIndex)
      if (nextIndex === 1) {
        this.$Bus.emit('changeToDocument')
      }
    },
    validationBasicInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.basicInfoRules.validate().then(success => {
          if (success) {
            this.$Bus.emit('updateBasic', params => {
              storage.setItem('kycBasicInfo', params)
              resolve(true)
            })
          } else {
            reject()
          }
        })
      })
    },
    validationDocuments() {
      return new Promise((resolve, reject) => {
        this.$refs.documentsRules.validate().then(success => {
          if (success) {
            this.$Bus.emit('updateDocuments', params => {
              storage.setItem('kycDocuments', params)
              resolve(true)
            })
          } else {
            reject()
          }
        })
      })
    },
    validationDetails() {
      this.showValidationInformation = false
      const validateTotal = this.$refs.detailsRules.$children[0].validateTotalAmountReceive()
      const validatePurpose = this.$refs.detailsRules.$children[0].validatePurposeSelected()
      return new Promise((resolve, reject) => {
        if (validateTotal && validatePurpose) {
          this.$refs.detailsRules.validate().then(success => {
            if (success) {
              this.$Bus.emit('updateDetail', params => {
                storage.setItem('kycDetail', params)
                this.showValidationInformation = true
                resolve(true)
              })
            } else {
              reject()
            }
          })
        } else {
          resolve(false)
        }
      })
    },
    validationInformationCheck() {
      return new Promise((resolve, reject) => {
        this.$refs.checkRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
.account-v {
  padding: 0.4rem 2rem;
  .navbar-brand{
    display: flex;
    align-items: center;
  }
  img {
    width: 36px;
    height: 36px;
  }
  .brand-text {
    color: #7367f0;
    font-weight: 600;
    letter-spacing: 0.01rem;
    font-size: 1.45rem;
    padding-left: 1rem;
    margin-bottom: 0;
  }

  .user-name {
    float: left;
    color: #6e6b7b;
    margin-right: 0.8rem;
    font-weight: 600 !important;
  }
}
.wizard-main {
  padding: 0 10%;
  .wizard-main-title {
    color: #5e5873;
    font-weight: 500;
    font-size: 1.6rem;
    margin: 3rem 0 1rem 1rem;
  }
}
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '~@core/scss/base/pages/app-ecommerce.scss';
  @import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>
