<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <h5 class="mb-0">
        Business Documents
      </h5>
      <small class="text-muted">
        Please upload the company registration certificate, company charter or equivalent.
      </small>
    </b-col>
    <b-col md="6">
      <b-form-group
        label="Company registration certificate"
        label-for="registrationCertificate"
      >
        <validation-provider
          ref="certificateFileValid"
          #default="{ errors }"
          name="Company registration certificate"
          rules="required"
        >
          <div class="file-wrap">
            <!-- v-model="certificateFile" -->
            <b-form-file
              id="registrationCertificate"
              :value="certificateFile"
              class="form-file"
              accept=".pdf,.jpg,.jpeg,.png,.bmp"
              placeholder="Choose a file..."
              :state="errors.length > 0 ? false:null"
              browse-text="upload"
              @change="fileChange(arguments[0],'certificateFileValid')"
            />
            <b-input-group>
              <b-form-input
                :value="certificateFile ? certificateFile.name : ''"
                type="text"
                placeholder="Choose a file..."
              />
              <b-input-group-append>
                <b-button variant="outline-primary">
                  Upload
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <small class="text-danger">{{ errors[0] }}</small>
          <br>
          <small class="text-muted">Support format: PDF, JPG, JPEG, PNG, BMP, no more than 10mb</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6" />
    <b-col md="6">
      <b-form-group
        label="Company charter or equivalent"
        label-for="equivalent"
      >
        <validation-provider
          ref="equivalentFileValid"
          #default="{ errors }"
          name="Company charter or equivalent"
          rules="required"
        >
          <div class="file-wrap">
            <!-- v-model="equivalentFile" -->
            <b-form-file
              id="equivalent"
              class="form-file"
              accept=".pdf,.jpg,.jpeg,.png,.bmp"
              :value="equivalentFile"
              placeholder="Choose a file..."
              :state="errors.length > 0 ? false:null"
              browse-text="upload"
              @change="fileChange(arguments[0],'equivalentFileValid')"
            />
            <b-input-group>
              <b-form-input
                :value="equivalentFile ? equivalentFile.name : ''"
                type="text"
                placeholder="Choose a file..."
              />
              <b-input-group-append>
                <b-button variant="outline-primary">
                  Upload
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <small class="text-danger">{{ errors[0] }}</small>
          <br>
          <small class="text-muted">Support format: PDF, JPG, JPEG, PNG, BMP, no more than 10mb</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6" />
    <b-col
      cols="12"
    >
      <h5 class="mb-0">
        Personal Documents
      </h5>
      <small class="text-muted">
        <p>Please provide a copy of the ID and address of the director, authorized person and ultimate beneficiary (if applicable).<br>The ultimate beneficiary refers to an individual who directly or indirectly holds more than 25% of the company shares.</p>
      </small>
    </b-col>
    <b-col md="6">
      <!-- Director 组件 -->
      <div v-if="isShowDirector">
        <document-add-component
          array-name="director"
          :small-title="personalTitle"
          :person-array.sync="directorArray"
          :country-array.sync="countryOption"
          @getData="getPersonArray(arguments[0], 'director')"
        />
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="addDirector"
      >
        Add Director
      </b-button>
    </b-col>
    <b-col md="6" />
    <b-col
      md="6"
      class="mt-2"
    >
      <!-- Ultimate Beneficiary 组件 -->
      <div v-if="isShowBeneficiary">
        <document-add-component
          array-name="beneficiary"
          :small-title="ultimateTitle"
          :person-array.sync="beneficiaryArray"
          :country-array.sync="countryOption"
          @getData="getPersonArray(arguments[0], 'beneficiary')"
        />
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="addBeneficiary"
      >
        Add Beneficiary
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  defineComponent, ref, getCurrentInstance, onBeforeMount, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import store from '@/store'
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BInputGroup, BInputGroupAppend, BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import storage from '@/libs/storage'
import { subUrlName, showToast } from '@/libs/utils'
import DocumentAddComponent from './documentAddComponent.vue'
import useAccountVerification from '../useAccountVerification'

export default defineComponent({
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    ValidationProvider,
    vSelect,
    DocumentAddComponent,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
  },
  setup() {
    const { proxy } = getCurrentInstance()
    onBeforeMount(() => {
      // 获取公共数据
      // store.dispatch('wallet/getCommon')
    })
    // option
    const countryOption = computed(() => store.state.wallet.countryList)
    const { uploadFile } = useAccountVerification()
    const certificateFile = ref(null)
    const equivalentFile = ref(null)
    const companyCertificateUrl = ref('')
    const companyCodeUrl = ref('')

    const personalTitle = ref('Director')
    const ultimateTitle = ref('Ultimate Beneficiary')
    // director
    const isShowDirector = ref(true)
    // beneficiary
    const isShowBeneficiary = ref(true)
    const directorArray = ref([{ tab_index: 0 }])
    const beneficiaryArray = ref([{ tab_index: 0 }])
    const directorParams = ref([])
    const beneficiaryParams = ref([])
    // watch(countryItem, countrys => {
    //   isShowDirector.value = false
    //   isShowBeneficiary.value = false
    //   proxy.$nextTick(() => {
    //     countryOption.value = countrys.map(val => ({ label: val.name, value: val.code }))
    //     directorArray.value[0] = { tab_index: 0 }
    //     beneficiaryArray.value[0] = { tab_index: 0 }
    //     // console.log('countryOption.value => ', countryOption.value)
    //     isShowDirector.value = true
    //     isShowBeneficiary.value = true
    //   })
    // })
    // watch(directorArray, () => {
    //   isShowDirector.value = false
    //   proxy.$nextTick(() => { isShowDirector.value = true })
    // })
    // watch(beneficiaryArray, () => {
    //   isShowBeneficiary.value = false
    //   proxy.$nextTick(() => { isShowBeneficiary.value = true })
    // })

    const addDirector = () => {
      directorArray.value.push({ tab_index: 0 })
    }
    const addBeneficiary = () => {
      beneficiaryArray.value.push({ tab_index: 0 })
    }

    const selectDirectorItem = (value, type) => {
      if (value.length === 0) {
        return []
      }
      // 筛选去掉key带有 type 的变量
      const params = value.filter(item => {
        Object.keys(item).forEach(m => {
          if (m.includes(type)) {
            // eslint-disable-next-line no-param-reassign
            delete item[m]
          }
        })
        return item
      })
      const newParams = []
      params.forEach(p => {
        const {
          country_code, id_front_url, id_back_url, passport_url, birth_place,
        } = p
        if (p.tab_index === 0) {
          newParams.push({ country_code, id_front_url, id_back_url })
        } else if (p.tab_index === 1) {
          newParams.push({ country_code, passport_url, birth_place })
        }
      })
      return newParams
    }

    proxy.$Bus.on('updateDocuments', cb => {
      const params = {
        documents: {
          company_certificate_url: companyCertificateUrl.value,
          company_code_url: companyCodeUrl.value,
          director: selectDirectorItem(directorParams.value, 'director'),
          beneficiary: selectDirectorItem(beneficiaryParams.value, 'beneficiary'),
        },
      }
      // console.log('params', params)
      // console.log('params string', JSON.stringify(params))
      cb(params)
    })

    const getPersonArray = (value, type) => {
      if (type === 'director') {
        // console.log('directorArray => ', directorArray.value)
        directorParams.value = value
        // console.log('directorParams => ', directorParams.value)
      } else if (type === 'beneficiary') {
        // console.log('beneficiaryArray => ', beneficiaryArray.value)
        beneficiaryParams.value = value
        // console.log('beneficiaryParams => ', beneficiaryParams.value)
      } else {}
    }

    const fileChange = (event, refName) => {
      proxy.$refs[refName].validate()
      const [singleFile] = event.target.files
      if (!singleFile) { return }
      // 限制上传文件的大小
      if (singleFile.size > 10 * 1024 * 1024) {
        showToast({ proxy, title: 'danger', message: 'The file cannot exceed 10mb' })
        return
      }
      setTimeout(async () => {
        if (refName === 'certificateFileValid') {
          companyCertificateUrl.value = await uploadFile(singleFile)
          certificateFile.value = new File([''], subUrlName(companyCertificateUrl.value), { type: 'image/jpeg' })
        } else if (refName === 'equivalentFileValid') {
          companyCodeUrl.value = await uploadFile(singleFile)
          equivalentFile.value = new File([''], subUrlName(companyCodeUrl.value), { type: 'image/jpeg' })
        } else {}
      }, 0)
    }

    proxy.$Bus.on('changeToDocument', () => {
      proxy.$nextTick(() => {
        const document = storage.getItem('kycDocuments')
        if (document) {
          const {
            company_certificate_url: companyCertificateUrlValue, company_code_url: companyCodeUrlValue, beneficiary: beneficiaryValue, director: directorValue,
          } = document.documents
          companyCertificateUrl.value = companyCertificateUrlValue
          certificateFile.value = new File([''], subUrlName(companyCertificateUrlValue), { type: 'image/jpeg' })
          companyCodeUrl.value = companyCodeUrlValue
          equivalentFile.value = new File([''], subUrlName(companyCodeUrlValue), { type: 'image/jpeg' })

          directorArray.value = []
          directorValue.forEach((item, index) => {
            directorArray.value[index] = {}
            directorArray.value[index].country_code = item.country_code
            directorArray.value[index][`director${index}_country_code`] = item.country_code
            if (item.birth_place) {
              directorArray.value[index].birth_place = item.birth_place
              directorArray.value[index][`director${index}_birth_place`] = item.birth_place
              directorArray.value[index].passport_url = item.passport_url
              directorArray.value[index][`director${index}_passport_url`] = new File([''], subUrlName(item.passport_url), { type: 'image/jpeg' })
              directorArray.value[index].tab_index = 1
            } else {
              directorArray.value[index].id_back_url = item.id_back_url
              directorArray.value[index][`director${index}_id_back_url`] = new File([''], subUrlName(item.id_back_url), { type: 'image/jpeg' })
              directorArray.value[index].id_front_url = item.id_front_url
              directorArray.value[index][`director${index}_id_front_url`] = new File([''], subUrlName(item.id_front_url), { type: 'image/jpeg' })
              directorArray.value[index].tab_index = 0
            }
          })
          directorParams.value = directorArray.value
          beneficiaryArray.value = []
          beneficiaryValue.forEach((item, index) => {
            beneficiaryArray.value[index] = {}
            beneficiaryArray.value[index].country_code = item.country_code
            beneficiaryArray.value[index][`beneficiary${index}_country_code`] = item.country_code
            if (item.birth_place) {
              beneficiaryArray.value[index].birth_place = item.birth_place
              beneficiaryArray.value[index][`beneficiary${index}_birth_place`] = item.birth_place
              beneficiaryArray.value[index].passport_url = item.passport_url
              beneficiaryArray.value[index][`beneficiary${index}_passport_url`] = new File([''], subUrlName(item.passport_url), { type: 'image/jpeg' })
              beneficiaryArray.value[index].tab_index = 1
            } else {
              beneficiaryArray.value[index].id_back_url = item.id_back_url
              beneficiaryArray.value[index][`beneficiary${index}_id_back_url`] = new File([''], subUrlName(item.id_back_url), { type: 'image/jpeg' })
              beneficiaryArray.value[index].id_front_url = item.id_front_url
              beneficiaryArray.value[index][`beneficiary${index}_id_front_url`] = new File([''], subUrlName(item.id_front_url), { type: 'image/jpeg' })
              beneficiaryArray.value[index].tab_index = 0
            }
          })
          beneficiaryParams.value = beneficiaryArray.value
        }
      })
    })

    return {
      certificateFile,
      equivalentFile,
      companyCodeUrl,
      companyCertificateUrl,

      personalTitle,
      ultimateTitle,
      countryOption,

      isShowDirector,
      directorArray,
      addDirector,

      isShowBeneficiary,
      beneficiaryArray,
      addBeneficiary,

      getPersonArray,
      fileChange,
    }
  },
  directives: {
    Ripple,
  },
})
</script>

<style lang="scss">
.file-wrap {
  position: relative;
  .form-file {
    position: absolute;
    width: 100%;
    z-index: 9;
    opacity: 0;
  }
}
</style>
