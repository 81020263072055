<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <h5 class="mb-0">
        Company Info
      </h5>
    </b-col>
    <b-col md="8">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="Registered country or region"
        label-for="RegisteredCountryOrRegion"
      >
        <v-select
          id="RegisteredCountryOrRegion"
          v-model="basicInfo.country_code"
          :options="countryOption"
          placeholder="Select One"
          :reduce="val => val.value"
          :disabled="true"
          :clearable="false"
        />
      </b-form-group>
    </b-col>
    <b-col md="8">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="Company registration number"
        label-for="CompanyRegistrationNumber"
      >
        <b-form-input
          id="CompanyRegistrationNumber"
          v-model="basicInfo.company_number"
          placeholder="Registration number..."
          :disabled="true"
        />
      </b-form-group>
    </b-col>
    <b-col md="8">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="Industry"
        label-for="Industry"
      >
        <v-select
          id="Industry"
          v-model="basicInfo.industry_code"
          :options="countryOption"
          placeholder="Select One"
          :reduce="val => val.value"
          :disabled="true"
          :clearable="false"
        />
      </b-form-group>
    </b-col>
    <b-col md="8">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="Website"
        label-for="Website"
      >
        <b-form-input
          id="Website"
          v-model="basicInfo.website"
          placeholder="Registration number..."
          :disabled="true"
        />

      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      class="mb-2"
    >
      <h5 class="mb-0">
        Business Documents
      </h5>
    </b-col>
    <b-col md="8">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="Company Registration Certificate:"
      >
        <b-link
          v-if="subUrlName(documents.company_certificate_url).includes('pdf')"
          target="_blank"
          :href="documents.company_certificate_url"
          style="width: 150px;height: 150px;border: 1px dashed #cccccc;display: block;"
        >
          <img
            style="width: 100px;height: 100px;margin: 23px auto;display: block;"
            src="@/assets/images/merchant/pdf.png"
            alt="pdf"
          >
        </b-link>
        <img
          v-else
          class="information-check-img"
          :src="documents.company_certificate_url"
          alt="company_certificate_url"
          @click="$previewPictrue(documents.company_certificate_url)"
        >
      </b-form-group>
    </b-col>
    <b-col md="8">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="Company Charter or Equivalent:"
      >
        <b-link
          v-if="subUrlName(documents.company_code_url).includes('pdf')"
          target="_blank"
          :href="documents.company_code_url"
          style="width: 150px;height: 150px;border: 1px dashed #cccccc;display: block;"
        >
          <img
            style="width: 100px;height: 100px;margin: 23px auto;display: block;"
            src="@/assets/images/merchant/pdf.png"
            alt="pdf"
          >
        </b-link>
        <img
          v-else
          class="information-check-img"
          :src="documents.company_code_url"
          alt="company_certificate_url"
          @click="$previewPictrue(documents.company_code_url)"
        >
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      class="mb-2"
    >
      <h5 class="mb-0">
        Personal Documents
      </h5>
    </b-col>
    <b-col
      v-for="(item, index) in documents.director"
      :key="`Director${index}`"
      md="8"
    >
      <span>Director {{ index + 1 }}:</span>
      <information-check-component
        :person-document="item"
        :country-option.sync="countryOption"
      />
    </b-col>
    <b-col
      v-for="(item, index) in documents.beneficiary"
      :key="`Beneficiary${index}`"
      md="8"
    >
      <span>Beneficiary {{ index + 1 }}:</span>
      <information-check-component
        :person-document="item"
        :country-option.sync="countryOption"
      />
    </b-col>
    <b-col
      cols="12"
      class="mb-2"
    >
      <h5 class="mb-0">
        Account Usage
      </h5>
    </b-col>
    <b-col md="8">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="From which country will you send money?"
        label-for="FromWhichCountrySend"
      >

        <v-select
          id="FromWhichCountrySend"
          v-model="details.country_code_send"
          :options="countryOption"
          placeholder="Select One"
          :reduce="val => val.value"
          :disabled="true"
          :clearable="false"
        />

      </b-form-group>
    </b-col>
    <b-col md="8">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="From which country will you recieve money?"
        label-for="FromWhichCountryRecieve"
      >

        <v-select
          id="FromWhichCountryRecieve"
          v-model="details.country_code_receive"
          :options="countryOption"
          placeholder="Select One"
          :reduce="val => val.value"
          :disabled="true"
          :clearable="false"
        />

      </b-form-group>
    </b-col>
    <b-col md="8">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="The total amount you expect to recieve is:"
        label-for="TheTotalAmount"
      >
        <b-form-input
          id="TheTotalAmount"
          :value="totalAmountReceiveText()"
          type="text"
          :disabled="true"
        />

      </b-form-group>
    </b-col>
    <b-col md="8">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="Your main purpose for using the Transfersmile Wallet is:"
        label-for="YourMainPurpose"
      >
        <b-form-input
          id="YourMainPurpose"
          :value="purposeText()"
          type="text"
          :disabled="true"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  defineComponent, getCurrentInstance, onBeforeMount, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BLink,
} from 'bootstrap-vue'
import store from '@/store'
import { subUrlName } from '@/libs/utils'
import informationCheckComponent from './informationCheckComponent.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BLink,
    informationCheckComponent,

    ValidationProvider,
    vSelect,
  },
  setup() {
    const { proxy } = getCurrentInstance()
    onBeforeMount(() => {
      // 获取公共数据
      // store.dispatch('wallet/getCommon')
    })
    // option
    const countryOption = computed(() => store.state.wallet.countryList)
    let { basic_info: basicInfo } = proxy.$storage.getItem('kycBasicInfo') || {}
    let { documents } = proxy.$storage.getItem('kycDocuments') || {}
    let { details } = proxy.$storage.getItem('kycDetail') || {}
    if (!basicInfo) { basicInfo = {} }
    if (!documents) { documents = {} }
    if (!details) { details = {} }
    const totalAmountReceiveOptions = ['$0 to $5000 USD', '$5000 to $10 000 USD', '$10 000 to $25 000 USD', '$25 000 to $150 000 USD', 'More than $150 000 USD']
    const purposeOptions = ['Recieving funds from online customers', 'Recieving funds from in store customers', 'Dispersing Gaming rewards']
    const totalAmountReceiveText = () => {
      if (details.total_amount_receive) { return totalAmountReceiveOptions[Number(details.total_amount_receive) - 1] }
      return ''
    }
    const purposeText = () => {
      if (details.purpose) { return purposeOptions[Number(details.purpose) - 1] }
      return ''
    }
    // console.log(' basicInfo => ', JSON.stringify(basicInfo))
    // console.log(' documents => ', JSON.stringify(documents))
    // console.log(' details => ', JSON.stringify(details))
    return {
      basicInfo, documents, details, countryOption, totalAmountReceiveText, purposeText, subUrlName,
    }
  },
})
</script>

<style lang="scss">
  .information-check-img {
    width: 100%;
    height: 150px;
    cursor: pointer;
  }
</style>
