var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Company Info ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Registered country or region","label-for":"countryCode"}},[_c('validation-provider',{ref:"countryCode",attrs:{"name":"Registered country or region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"countryCode","clearable":false,"value":_vm.country_code,"options":_vm.countryOption,"placeholder":"Select One","reduce":function (val) { return val.value; },"state":errors.length > 0 ? false:null},on:{"close":function () { return _vm.$refs.countryCode.validate(); }},model:{value:(_vm.country_code),callback:function ($$v) {_vm.country_code=$$v},expression:"country_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}}),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Company registration number","label-for":"registrationNumber"}},[_c('validation-provider',{attrs:{"name":"Company registration number","rules":"required|validRegistrationNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"registrationNumber","type":"text","state":errors.length > 0 ? false:null,"placeholder":"Registration number..."},model:{value:(_vm.company_number),callback:function ($$v) {_vm.company_number=$$v},expression:"company_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}}),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Industry","label-for":"industryCode"}},[_c('validation-provider',{ref:"industryCode",attrs:{"name":"Industry","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"industryCode","clearable":false,"value":_vm.industry_code,"options":_vm.countryOption,"placeholder":"Select One","reduce":function (val) { return val.value; },"state":errors.length > 0 ? false:null},on:{"close":function () { return _vm.$refs.countryCode.validate(); }},model:{value:(_vm.industry_code),callback:function ($$v) {_vm.industry_code=$$v},expression:"industry_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}}),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Website","label-for":"website"}},[_c('validation-provider',{attrs:{"name":"Website","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"website","type":"text","state":errors.length > 0 ? false:null,"placeholder":"https://www.website.com","formatter":function (value) { return _vm.sliceStr(value, 200); }},model:{value:(_vm.website),callback:function ($$v) {_vm.website=$$v},expression:"website"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"document-tips"},[_c('h3',[_vm._v("To set up an account, please provide the following documents:")]),_c('ul',[_c('li',[_vm._v("Company registration certificate")]),_c('li',[_vm._v("Company policy ")]),_c('li',[_vm._v("Personal address information and identity documents of each company executive.")]),_c('li',[_vm._v("Personal ID and address of the final beneficiary holding 25% or more of the company's shares (if applicable).")])])])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"selected"}},[_c('validation-provider',{attrs:{"name":"selected","rules":"selectedTrue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"selected"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._v(" I confirm that I will be the administrator of this business account and have been authorized to fill out this form on behalf of this business. ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }