<template>
  <div>
    <b-form-group
      label-cols="4"
      label-cols-lg="4"
      label="Country or Region of Document Issue:"
      label-for="address"
    >
      <v-select
        id="registrationNumber"
        v-model="personDocument.country_code"
        :options="countryOption"
        placeholder="Select One"
        :reduce="val => val.value"
        :disabled="true"
        :clearable="false"
      />
    </b-form-group>
    <!-- Identity Document -->
    <template v-if="!personDocument.birth_place">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="Type:"
        label-for="registrationNumber"
      >
        <b-form-input
          id="registrationNumber"
          value="Identity Document"
          placeholder="Registration number..."
          :disabled="true"
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="Front:"
        label-for="registrationNumber"
      >
        <b-link
          v-if="subUrlName(personDocument.id_front_url).includes('pdf')"
          style="width: 150px;height: 150px;border: 1px dashed #cccccc;display: block;"
          target="_blank"
          :href="personDocument.id_front_url"
        >
          <img
            style="width: 100px;height: 100px;margin: 23px auto;display: block;"
            src="@/assets/images/merchant/pdf.png"
            alt="pdf"
          >
        </b-link>
        <img
          v-else
          class="information-check-img"
          :src="personDocument.id_front_url"
          alt="company_certificate_url"
          @click="$previewPictrue(personDocument.id_front_url)"
        >
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="Back:"
        label-for="registrationNumber"
      >
        <b-link
          v-if="subUrlName(personDocument.id_back_url).includes('pdf')"
          style="width: 150px;height: 150px;border: 1px dashed #cccccc;display: block;"
          target="_blank"
          :href="personDocument.id_back_url"
        >
          <img
            style="width: 100px;height: 100px;margin: 23px auto;display: block;"
            src="@/assets/images/merchant/pdf.png"
            alt="pdf"
          >
        </b-link>
        <img
          v-else
          class="information-check-img"
          :src="personDocument.id_back_url"
          alt="company_certificate_url"
          @click="$previewPictrue(personDocument.id_back_url)"
        >
      </b-form-group>
    </template>
    <!-- Passport -->
    <template v-if="personDocument.birth_place">
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="Type:"
        label-for="registrationNumber"
      >
        <b-form-input
          id="registrationNumber"
          value="Passport"
          placeholder="Registration number..."
          :disabled="true"
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="Personal Info Page:"
        label-for="registrationNumber"
      >
        <b-link
          v-if="subUrlName(personDocument.passport_url).includes('pdf')"
          style="width: 150px;height: 150px;border: 1px dashed #cccccc;display: block;"
          target="_blank"
          :href="personDocument.passport_url"
        >
          <img
            style="width: 100px;height: 100px;margin: 23px auto;display: block;"
            src="@/assets/images/merchant/pdf.png"
            alt="pdf"
          >
        </b-link>
        <img
          v-else
          class="information-check-img"
          :src="personDocument.passport_url"
          alt="company_certificate_url"
          @click="$previewPictrue(personDocument.passport_url)"
        >
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="Place of birth:"
        label-for="registrationNumber"
      >
        <b-form-input
          id="registrationNumber"
          v-model="personDocument.birth_place"
          placeholder="Registration number..."
          :disabled="true"
        />
      </b-form-group>
    </template>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormGroup,
  BFormInput,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { subUrlName } from '@/libs/utils'

export default defineComponent({
  components: {
    BFormGroup,
    BFormInput,
    BLink,
    vSelect,
  },
  setup() {
    return { subUrlName }
  },
  props: {
    personDocument: {
      type: Object,
      default: () => {},
    },
    countryOption: {
      type: Array,
      default: () => [],
    },
  },
})
</script>
